// extracted by mini-css-extract-plugin
export var aizukkoIcon = "aizukko_plus__aizukkoIcon-L09Bh";
export var animeImg = "aizukko_plus__animeImg-JpTzc";
export var appDownload = "aizukko_plus__appDownload-GXYyd";
export var appImage = "aizukko_plus__appImage-eKIWS";
export var appstore = "aizukko_plus__appstore-z9JEB";
export var barText = "aizukko_plus__barText-01iq+";
export var cardClose = "aizukko_plus__cardClose-br3wm";
export var cardText = "aizukko_plus__cardText-O3qSO";
export var cardTitle = "aizukko_plus__cardTitle-tXunR";
export var cardTop = "aizukko_plus__cardTop-FV4ca";
export var circle = "aizukko_plus__circle-28heD";
export var circleNumber = "aizukko_plus__circleNumber-daqt-";
export var circleText = "aizukko_plus__circleText-nnYhv";
export var downloadBox = "aizukko_plus__downloadBox-UM2-7";
export var downloadIcon = "aizukko_plus__downloadIcon-9jMb1";
export var downloadLink = "aizukko_plus__downloadLink-x1Vg8";
export var downloadText = "aizukko_plus__downloadText-DDtXO";
export var downloadWrapper = "aizukko_plus__downloadWrapper-vhcAD";
export var flowBar = "aizukko_plus__flowBar-Pw2E2";
export var flowBox = "aizukko_plus__flowBox-+tF-Q";
export var forApp = "aizukko_plus__forApp-HkvpG";
export var googleplay = "aizukko_plus__googleplay-JulN0";
export var imgBox = "aizukko_plus__imgBox-hwnJE";
export var infoBox = "aizukko_plus__infoBox-jN6GZ";
export var modal = "aizukko_plus__modal-RcknA";
export var modalCard = "aizukko_plus__modalCard-wzqm+";
export var modalOverlay = "aizukko_plus__modalOverlay-6v9t0";
export var observe = "aizukko_plus__observe-Lpg-6";
export var sectionBox = "aizukko_plus__sectionBox-4JXkj";
export var smartcityBox = "aizukko_plus__smartcityBox-1Y4mB";
export var smartcityLink = "aizukko_plus__smartcityLink-d+AY3";
export var smartcitySpace = "aizukko_plus__smartcitySpace-0etb+";
export var smartcitySummary = "aizukko_plus__smartcitySummary-amBa1";
export var smartcityText = "aizukko_plus__smartcityText-ifku5";
export var smartcityTitle = "aizukko_plus__smartcityTitle-l8lLA";
export var smartcityWrapper = "aizukko_plus__smartcityWrapper-fcuES";
export var svg = "aizukko_plus__svg-saC6Q";